import React from 'react'
import PersonLabel from '../PersonLabel'

export default function Artist ({ artistName, artistPicture, artistLink }: { artistName: string, artistPicture: string, artistLink: string }) {
    if (artistLink === "NO_LINK") {
        return (
            <PersonLabel
                label="Artist"
                person={{ name: artistName, link: artistPicture }}
            />
        )
    } else {
        return (
            <a href={artistLink} rel="noopener noreferrer" target="_blank">
                <PersonLabel
                    label="Artist"
                    person={{ name: artistName, link: artistPicture }}
                />
            </a>
        )
    }
}