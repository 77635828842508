import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom'

import Home from './pages/Home'
import CommissionView from './pages/CommissionView'
import Settings from './pages/Settings'
import RefSheet from './pages/RefSheet'

export default function Router () {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/"         element={<Home />} />
                <Route path="/ref"      element={<RefSheet />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/:slug"    element={<CommissionView />} />
            </Routes>
        </BrowserRouter>
    )
}