import React from 'react'
import type { Character as TCharacter } from '../../data'
import Artist from '../Artist'
import Character from '../Character'

export default function CommissionDetails (
    {
        date,

        artistName,
        artistPicture,
        artistLink,

        character
    }: {
        date: Date,

        artistName: string,
        artistPicture: string,
        artistLink: string,

        character: TCharacter | TCharacter[],
    }
) {
    return (
        <section id="commission-details" className="flex flex-col mt-2">
            <div className="flex space-x-2">
                <span className="self-center text-sm text-gray-600">
                    Date
                </span>
                <time dateTime={date.toString()} className="text-sm">
                    {date.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </time>
            </div>
            <div className="flex pt-2 space-x-4">
                <Artist artistName={artistName} artistPicture={artistPicture} artistLink={artistLink} />
                <Character character={character} />
            </div>
        </section>
    )
}