import React from "react";
import BackToHome from "../components/BackToHome";

export default function Settings({ backTo }: { backTo?: string }) {
  const [seeNsfw, setSeeNsfw] = React.useState(false);
  const [unblurNsfwOnHome, setUnblurNsfwOnHome] = React.useState(false)
  const [columns, setColumns] = React.useState(3);

  React.useEffect(() => {
    const nsfw = localStorage.getItem("nsfw");
    setSeeNsfw(nsfw === "true");

    const unblur = localStorage.getItem("unblur-nsfw");
    setUnblurNsfwOnHome(unblur === "true");

    const cols = parseInt(localStorage.getItem("columns")?.toString() || "3");
    setColumns(cols);
  }, []);

  function setNsfwSetting (newSetting: boolean) {
    localStorage.setItem("nsfw", `${newSetting}`);
    setSeeNsfw(newSetting);
  }

  function setBlurNsfwSetting (newSetting: boolean) {
    localStorage.setItem("unblur-nsfw", `${newSetting}`);
    setUnblurNsfwOnHome(newSetting);
  }

  function setColCountSetting (e: React.ChangeEvent<HTMLInputElement>) {
    localStorage.setItem("columns", `${e.target.value}`);
    setColumns(parseInt(e.target.value));
  }

  return (
    <div className="p-4">
      <BackToHome text="Back" to={backTo ?? '/'} />
      <h1 className="my-2 text-2xl font-bold">Settings</h1>
      <p>Settings are saved to your browser. <strong>Clearing your browser's cached data may remove your settings!</strong></p>
      <br />
      <div className="flex flex-col space-y-1">
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-gray-600">
            See NSFW content? &#40;By enabling this, you agree that you are 18 years of age or older.&#41;
          </span>
          <div className="flex space-x-1">
            <input
              className="self-center"
              type="checkbox"
              checked={seeNsfw}
              onChange={() => setNsfwSetting(!seeNsfw)}
            />
            <span className="self-center text-lg">
              {seeNsfw ? "Yes" : "No"}
            </span>
          </div>
          {seeNsfw && (
            <div className="flex flex-col space-y-1">
              <span className="text-sm text-gray-600">
                Unblur NSFW content on home?
              </span>
              <div className="flex space-x-1">
                <input
                  className="self-center"
                  type="checkbox"
                  disabled={!seeNsfw}
                  checked={unblurNsfwOnHome}
                  onChange={() => setBlurNsfwSetting(!unblurNsfwOnHome)}
                />
                <span className="self-center text-lg">
                  {unblurNsfwOnHome ? "Yes" : "No"}
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col space-y-1">
            <span className="text-sm text-gray-600">
              Columns on home
            </span>
            <div className="flex space-x-1">
              <input
                className="self-center px-4 py-2 border border-gray-200 rounded-md"
                type="number"
                value={columns}
                onChange={setColCountSetting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
