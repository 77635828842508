import React from 'react'
import type { ShowcaseEntry } from '@/app/data'
import { userWantsNsfwContent } from '@/app/lib/local-storage'
import CommissionViewbox from '@/app/components/CommissionViewbox'
import NsfwWarning from '@/app/components/NsfwWarning'

const commissionIsNsfwAndUserDoesntWantNsfwContent = (item: ShowcaseEntry) => (item.isNsfw as boolean) && !userWantsNsfwContent()

function SingleCommView ({ data }: { data: ShowcaseEntry }) {
    return (
        <div className="flex flex-col space-y-2">
            {commissionIsNsfwAndUserDoesntWantNsfwContent(data) ? (
                <NsfwWarning />
            ) : (
                <CommissionViewbox
                    pictureUrl={data.link as string}
                    pictureAlt={data.altText as string}
                    nftProtection
                />
            )}
        </div>
    )
}

export default SingleCommView