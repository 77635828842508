import React from 'react'
import { getKeyWithDefault } from '@/app/lib/local-storage'

import { Link } from 'react-router-dom'

function NsfwWarning () {
    const setNsfwAndReload = () => {
        localStorage.setItem("nsfw", "true")
        window.location.reload()
    }
    return (
        <div className="p-4 my-2 bg-gray-100 border border-gray-300 rounded-md">
            <p className="text-gray-600">This content is not safe for work. You can change this setting in your <Link to="/settings" className="text-blue-500 hover:underline">Settings</Link>.</p>
            {process.env.NODE_ENV === 'development' && (
                <>
                    <hr className="my-2 text-gray-600" />
                    <p className="font-mono text-sm text-gray-600">
                        getKeyWithDefault("nsfw", "false") === {getKeyWithDefault("nsfw", "false")}
                        <button className="ml-2 text-blue-500 hover:underline" onClick={setNsfwAndReload}>Set to true and re-render</button>
                    </p>
                </>
            )}
        </div>
    )
}

export default NsfwWarning