import React from "react";
import { userWantsNsfwContent, userWantsNsfwContentUnblurred } from "../../lib/local-storage";
import ParentalBlock from "../ParentalBlock";
import type { HomeData } from '../../data'
import {Link} from "react-router-dom";

function HomeItem ({ item }: { item: HomeData }) {
    const [w, setW] = React.useState<number>(0)
    const [h, setH] = React.useState<number>(0)
    const [imageSrc, setImageSrc] = React.useState<string>("")
    const [imageAlt, setImageAlt] = React.useState<string>("")
    const [imageNsfw, setImageNsfw] = React.useState<boolean>(false)
    const [hideWithParentalBlock, setHideWithParentalBlock] = React.useState<boolean>(false)

    React.useEffect(() => {
        // Get whether or not we should render ParentalBlock instead of the item
        setHideWithParentalBlock(!(userWantsNsfwContent() && userWantsNsfwContentUnblurred()))

        // very complicated check to see if the item is a multicomm
        if (
            Array.isArray(item.link)
            && item.link.length > 1
            && Array.isArray(item.isNsfw)
            && item.isNsfw.length === item.link.length
            && Array.isArray(item.altText)
            && item.altText.length === item.link.length
        ) {
            setImageSrc(item.link[0])
            setImageAlt(item.altText[0])
            setImageNsfw(item.isNsfw[0])
        } else {
            setImageSrc(item.link as string)
            setImageAlt(item.altText as string)
            setImageNsfw(item.isNsfw as boolean)
        }

        // get image w/h, needed for ParentalBlock
        const image = new Image()
        image.src = imageSrc

        // Set w and h to the image's width and height.
        image.onload = () => {
            setW(image.width)
            setH(image.height)
        }
    }, [item, imageSrc])

    return (
        <Link to={item.slug}>
            {hideWithParentalBlock && imageNsfw ? (
                <ParentalBlock width={w} height={h} title={"Content is NSFW"} imageSrc={imageSrc} />
            ) : (
                <img
                    src={imageSrc}
                    alt={imageAlt}
                    loading={'lazy'}
                    draggable={false}
                    onContextMenu={e => e.preventDefault()}
                />
            )}
        </Link>
    )
}

export default HomeItem