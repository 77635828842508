import React from 'react'

export default function CommissionViewbox ({ pictureUrl, pictureAlt, nftProtection = true }: { pictureUrl: string, pictureAlt: string, nftProtection?: boolean }) {
    return (
        <div className="w-full h-auto">
            <img
                loading="lazy"
                src={pictureUrl}
                alt={pictureAlt}
                draggable={!nftProtection}
                onContextMenu={nftProtection ? e => e.preventDefault() : () => {/* noop */}}
            />
        </div>
    )
}