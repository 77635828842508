import React from 'react'
import HomeNav from '../components/HomeNav'
import BackToHome from '../components/BackToHome'
import CommissionViewbox from '../components/CommissionViewbox'
import CommissionDetails from '../components/CommissionDetails'
import type { Artist, Character } from '../data'
import { userWantsNsfwContent } from '../lib/local-storage'
import NsfwWarning from '../components/NsfwWarning'

export default function RefSheet() {
    const [refSheetArtist, setRefSheetArtist] = React.useState<Artist>({ name: '', photo: '', link: '' })
    const [character, setCharacter] = React.useState<Character>({ name: '', link: '' })
    const [sfwRefLink, setSfwRefLink] = React.useState<string>('')
    const [nsfwRefLink, setNsfwRefLink] = React.useState<string>('')
    const [refSheetDate, setRefSheetDate] = React.useState<Date>(new Date())

    React.useEffect(() => {
        import('../data' /* webpackMode: "lazy", webpackChunkName: "BoxofficeStatic" */).then(module => {

            setRefSheetArtist(module.REF_SHEET.artist)
            setCharacter(module.REF_SHEET.character as Character)
            setSfwRefLink(module.REF_SHEET.link[0])
            setNsfwRefLink(module.REF_SHEET.link[1])
            setRefSheetDate(module.REF_SHEET.date)


            const makeFileUrl = module.makeFileUrl
            setRefSheetArtist(module.REF_SHEET_ARTIST)
            setCharacter(module.TAIKO_CHAR)
            setSfwRefLink(makeFileUrl('ref_taiko_sfw.png'))
            setNsfwRefLink(makeFileUrl('ref_taiko_nsfw.png'))
            setRefSheetDate(new Date("12/23/2019"))
        })
    }, [])
    return (
        <>
            <HomeNav />
            <section className="container flex flex-col max-w-xl pt-4 mx-auto">
                <BackToHome text={"Back"} />
                {/* Slot for photo */}
                <div className="flex flex-col space-y-2">
                    <CommissionViewbox
                        pictureUrl={sfwRefLink}
                        pictureAlt="A safe for work reference sheet for my character Taiko. A gray and brown pizzly bear."
                        nftProtection={false}
                    />

                    {!userWantsNsfwContent ? (
                        <NsfwWarning />
                    ) : (
                        <CommissionViewbox
                            pictureUrl={nsfwRefLink}
                            pictureAlt="A not safe for work reference sheet for my character Taiko. A gray and brown pizzly bear."
                            nftProtection={false}
                        />
                    )}
                </div>

                <CommissionDetails
                    date={refSheetDate}
                    artistName={refSheetArtist.name}
                    artistPicture={refSheetArtist.photo}
                    artistLink={refSheetArtist.link}
                    character={character}
                />
            </section>
        </>
    )
}