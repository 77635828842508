import React from 'react'
import type { Character as TCharacter } from '../../data'
import PersonLabel from '../PersonLabel'

export default function Character ({ character }: { character: TCharacter | TCharacter[] }) {
    return (
        <PersonLabel
            label="Character"
            person={character}
        />
    )
}