import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeNav () {
    return (
        <nav className="flex items-end justify-end px-4 py-2 space-x-4">
            <Link to="/settings" className='flex self-center'>
                <svg xmlns="http://www.w3.org/2000/svg" className="self-center w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-label='Settings'>
                    <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg>
                <p className="ml-1">
                    Settings
                </p>
            </Link>
            <Link to="/ref" className='flex self-center'>
                <svg xmlns="http://www.w3.org/2000/svg" className="self-center w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-label="Reference sheet">
                    <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                </svg>
                <p className="ml-1">
                    Reference Sheet
                </p>
            </Link>
        </nav>
    )
}