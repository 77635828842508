import React from 'react'
import { useParams } from 'react-router-dom'
import type { ShowcaseEntry } from '../data'
import BackToHome from '../components/BackToHome'
import CommissionDetails from '../components/CommissionDetails'
import MultiCommView from '../components/MultiCommView'
import SingleCommView from '../components/SingleCommView'
import HomeNav from '../components/HomeNav'


function CommissionNotFoundText() {
    return (
        <p>
            No commission with this slug exists.
        </p>
    )
}

function CommissionViewSelector({ data }: { data: ShowcaseEntry }) {
    if (Array.isArray(data.link)) {
        return <MultiCommView data={data} />
    } else {
        return <SingleCommView data={data} />
    }
}

export default function CommissionView() {
    const [commission, setCommission] = React.useState<ShowcaseEntry | null>(null)
    const { slug } = useParams()

    React.useEffect(() => {
        import('../data' /* webpackMode: "lazy", webpackChunkName: "BoxofficeStatic" */).then(module => {
            const commissionData = module.data.find(item => item.slug === `/${slug}`)

            if (commissionData)
                setCommission(commissionData)
        })
    }, [slug])

    return (
        <>
            <HomeNav />
            <section className="container flex flex-col max-w-xl py-4 mx-auto">
                <BackToHome text={"Back"} />

                {commission === null
                    ? <CommissionNotFoundText />
                    : (
                        <>
                            <CommissionViewSelector data={commission} />
                            <CommissionDetails
                                date={commission.date}
                                artistName={commission.artist.name}
                                artistPicture={commission.artist.photo}
                                artistLink={commission.artist.link}
                                character={commission.character}
                            />

                            {/* TODO: This should be componentized or shoved into SingleCommView/MultiCommView */}
                            {commission.description && (
                                <p>
                                    <strong>Description:</strong>
                                    <br />
                                    {commission.description}
                                </p>
                            )}
                        </>
                    )}
            </section>
        </>
    )
}