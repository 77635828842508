import React from 'react'
import { Link } from 'react-router-dom'

export default function BackToHome ({ text, to }: { text: string, to?: string }) {
    return (
        <Link to={to ?? '/'} className="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="self-center w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span className="self-center">{text}</span>
        </Link>
    )
}