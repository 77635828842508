import React from 'react'
import { ShowcaseEntry } from '@/app/data';
import { userWantsNsfwContent } from '@/app/lib/local-storage';
import CommissionViewbox from '@/app/components/CommissionViewbox';
import NsfwWarning from '@/app/components/NsfwWarning';

const multiCommEntryIsNsfw = (item: ShowcaseEntry, index: number) => {
    return (item.isNsfw as boolean[])[index]
}

function MultiCommView ({ data }: { data: ShowcaseEntry }) {
    const entryNsfwAndUserDoesNotWantNsfw = (item: ShowcaseEntry, index: number) => multiCommEntryIsNsfw(item, index) && !userWantsNsfwContent()
    return (
        <div className="flex flex-col space-y-2">
            {(data.link as string[]).map((link, index) => (
                entryNsfwAndUserDoesNotWantNsfw(data, index)
                    ? <NsfwWarning />
                    : <CommissionViewbox
                        key={link}
                        pictureUrl={link}
                        pictureAlt={(data.altText as string[])[index]}
                        nftProtection
                      />
            ))}
        </div>
    )
}

export default MultiCommView