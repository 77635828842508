import React from 'react'

function PersonWithProfileAndUrl ({ person }: { person: { name: string, link: string, url: string }}) {
    return (
        <a href={person.url} className="flex space-x-1" rel="noopener noreferrer" target="_blank">
            <PersonWithProfile person={{ name: person.name, link: person.link }} />
        </a>
    )
}
function PersonWithProfile ({ person }: { person: { name: string, link: string } }) {
    return (
        <>
            {person.link !== 'NO_IMAGE' && (
                <img
                    src={person.link}
                    alt={person.name}
                    className="self-center w-6 h-6 rounded-md mr-1"
                    draggable={false}
                    onContextMenu={(e) => e.preventDefault()}
                />
            )}
            <span className="self-center text-lg">{person.name}</span>
        </>
    )
}

export default function PersonLabel ({ label, person }: { label: string, person: { name: string, link: string, url?: string } | { name: string, link: string, url?: string }[]}) {
    return (
        <div className="flex flex-col space-y-1">
            <span className="text-sm text-gray-600">
                {label}
            </span>
            <div className="flex space-x-1">
                {Array.isArray(person) ? person.map(({ name, link, url }, index) => (
                    <>
                        {url ? (
                            <PersonWithProfileAndUrl person={{ name, link, url }} />
                        ) : (
                            <PersonWithProfile person={{ name, link }} />
                        )}
                    </>
                )) : (
                    <>
                        {person.url ? ( // NOSONAR
                            <PersonWithProfileAndUrl person={{ name: person.name, link: person.link, url: person.url }} />
                        ) : (
                            <PersonWithProfile person={person} />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}