export function getKeyWithDefault(key: string, defaultValue: string): string {
    const value = localStorage.getItem(key)
    if (value === null) {
        return defaultValue
    }
    return value
}

const BOXOFFICE_SANE_DEFAULTS: { [key: string]: string } = {
    "nsfw": "false",
    "unblur-nsfw": "false",
    "columns": "3"
}

export function setSaneDefaults () {
    Object.keys(BOXOFFICE_SANE_DEFAULTS).forEach(key => {
        const value = BOXOFFICE_SANE_DEFAULTS[key]
        if (localStorage.getItem(key) === null) {
            localStorage.setItem(key, value)
        }
    })
}

export function userWantsNsfwContent(): boolean {
    return getKeyWithDefault("nsfw", "false") === "true"
}

export function userWantsNsfwContentUnblurred(): boolean {
    return getKeyWithDefault("unblur-nsfw", "false") === "true"
}