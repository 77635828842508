import React from 'react'

function ParentalBlock (
    {
        width,
        height,
        title,
        imageSrc
    }: {
        width: number,
        height: number,
        title: string,
        imageSrc: string
    }
) {
    return (
        <div
            className={"bg-gray-400"}
            title={title}
            style={{ marginBottom: '30px' }}
        >
            <img src={imageSrc} style={{ opacity: 0 }} alt={""} />
        </div>
    )
}

export default ParentalBlock