import React from 'react'
import Masonry from 'react-masonry-css'
import type { HomeData } from '../data'
import HomeNav from '../components/HomeNav'
import HomeItem from "../components/HomeItem";
import { getKeyWithDefault } from '../lib/local-storage'

export default function Home () {
    const [data, setData] = React.useState<HomeData[] | []>([])

    React.useEffect(() => {
        import('../data' /* webpackMode: "lazy", webpackChunkName: "BoxofficeStatic" */).then(module => {
            setData(module.homeData)
        })
    }, [])

    return (
        <div className="flex flex-col space-y-1">
            <HomeNav />
            <Masonry
                breakpointCols={parseInt(getKeyWithDefault('columns', '3'))}
                className='my-masonry-grid'
                columnClassName='my-masonry-grid_column'
            >
                {data.map(item => (
                    <HomeItem item={item} key={item.slug} />
                ))}
            </Masonry>
        </div>
    )
}